const WalletIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        id="Capa_1"
        width="20"
        height="20"
        fill="currentColor"
        stroke="currentColor"
        version="1.1"
        viewBox="0 0 969.486 969.486"
    >
        <path d="M806.582 235.309 766.137 87.125l-137.434 37.51L571.451 9.072 114.798 235.309H0v725.105h907.137V764.973h62.35v-337.53h-62.352V235.309zm-88.141-64.679 17.654 64.68H481.457l111.159-30.339 66.848-18.245zm120.694 721.784H68V303.31h771.135v124.132H492.291v337.529h346.846v127.441h-.002zm62.351-195.441H560.291v-201.53h341.195zM540.707 100.191l21.15 42.688-238.955 65.218z"></path>
        <path d="M614.146 564.57v66.582h66.584V564.57h-22.232z"></path>
    </svg>
);

export default WalletIcon