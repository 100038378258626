import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createTokenFetch, getTokenListFetch } from "./TokenAPI";
import { toast } from "react-toastify";

const initialState = {
    tokens: [],
    state: 'idle'
}

export const getTokenListRequest = createAsyncThunk(
    'token/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getTokenListFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const createTokenRequest = createAsyncThunk(
    'token/create',
    async (data, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await createTokenFetch(token, data);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const TokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getTokenListRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTokenListRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.tokens = data
            })
    }
})

export const getTokenState = (state) => state.token.tokens

export default TokenSlice.reducer