import { Button, Card, Col, Form, Input, Row, Spin } from "antd"
import { getUserStatusState, updatePasswordMeRequest } from "../../../Stores/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const Privacy = () => {
    const statusData = useSelector(getUserStatusState);
    const dispath = useDispatch();
    const [form] = Form.useForm();

    const onSubmit = () => {
        form.validateFields().then((values) => {
            let data = values

            // Check repeat password
            if (data.new_password !== data.new_password_repeat) {
                toast.error("Password nhập lại không khớp.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return
            }

            delete data['new_password_repeat'];

            dispath(updatePasswordMeRequest(data));

        }).catch(e => { })

        // //filter field have data
        // Object.keys(data).forEach(key => {
        //     if (!data[key]) delete data[key];
        // });

        // dispath(updateUserMeRequest(data))
    }

    return <>
        <Form
            layout="vertical"
            form={form}
            validateMessages={{ required: '${label} is required!', }}
        >
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={12}>
                    <Card>
                        <Spin spinning={statusData == "loading" ? true : false}>
                            <p className="font-bold text-xl pb-4">Đổi mật khẩu</p>
                            <Form.Item
                                name="current_password"
                                label={"Mật khẩu hiện tại"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password></Input.Password>
                            </Form.Item>
                            <Form.Item
                                name="new_password"
                                label={"Mật khẩu mới"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password></Input.Password>
                            </Form.Item>
                            <Form.Item
                                name="new_password_repeat"
                                label={"Nhập lại mật khẩu mới"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input.Password></Input.Password>
                            </Form.Item>
                            <Button onClick={onSubmit} type="primary">Lưu thay đổi</Button>
                        </Spin>
                    </Card>
                </Col>
            </Row>

        </Form>
    </>
}

export default Privacy