import axios from "axios"
const HOST = process.env.REACT_APP_API_HOST

export const createKeyFetch = async (token, dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.post(HOST + "/api/v1/keys/", dataInput, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getKeysFetch = async (token, search) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/keys/", {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getDetailKeyFetch = async (token, keyID) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/keys/" + keyID, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const refreshKeysFetch = async (token, keyID) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.put(HOST + "/api/v1/keys/" + keyID, {}, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const deleteKeysFetch = async (token, keyID) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.delete(HOST + "/api/v1/keys/" + keyID, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}