import React, { useEffect, useState } from 'react';
import { Space, Table, Tag, Tooltip } from 'antd';
import ModalViewKey from './Modal/ViewKey';
import { useDispatch } from 'react-redux';
import { deleteKeysRequest, getKeysRequest } from '../../../Stores/KeySlice';
import { toast } from 'react-toastify';

const TableComponent = ({ keyData, cyclesData,
    cyclesFillterData, statusFillterData, searchData, lang
}) => {
    const [data, setData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [keyView, setKeyView] = useState({});
    const dispatch = useDispatch();
    const [dataFilter, setDataFilter] = useState([])

    useEffect(() => {
        if (keyData && cyclesData) {
            const mapCycles = {}
            cyclesData.map((data) => {
                mapCycles[data.id] = {
                    name: data.name,
                    price: data.price,
                }
            })

            const updatedKeyData = keyData.map((key) => ({
                ...key,
                cycle_data: mapCycles[key.cycle_id]
            }));

            // console.log(updatedKeyData)
            setData(updatedKeyData)
            setDataFilter(updatedKeyData)
        }
    }, [keyData, cyclesData])

    useEffect(() => {
        // Filter
        let updatedKeyData = [...data]
        updatedKeyData = updatedKeyData.filter((key) =>
            cyclesFillterData.some(cycle => cycle.id == key.cycle_id)
        )

        statusFillterData.map((filter) => {
            updatedKeyData = updatedKeyData.filter((key) => {
                if (key.status == filter.status) {
                    return true
                }
            })
        })

        updatedKeyData = updatedKeyData.filter((key) => {
            if (searchData && key.cycle_data) {
                if (key.cycle_data.name.indexOf(searchData) !== -1) {
                    return true
                }
            }
            if (!searchData) {
                return true
            }
        })

        setDataFilter(updatedKeyData)
    }, [cyclesFillterData, statusFillterData, searchData])

    const onClickView = (id) => {
        setKeyView(id)
        setIsModalOpen(true)
    }

    const onDelete = (id) => {
        dispatch(deleteKeysRequest(id)).then((response) => {
            dispatch(getKeysRequest())
        });
    }

    const columnsData = [
        {
            title: lang.created_at || 'Ngày tạo',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => <p>{text}</p>,
        },
        {
            title: lang.package || 'Gói',
            dataIndex: 'cycle',
            key: 'cycle',
            render: (_, record) => {
                return record.cycle_data && <p>{record.cycle_data.name}</p>
            },
    
        },
        {
            title: lang.value || 'Giá trị',
            dataIndex: 'value',
            key: 'value',
            width: 200,
            render: (_, record) => {
                return <Tooltip placement="top" title={"Copy"}>
                    <a onClick={() => {
                         navigator.clipboard.writeText(record.id)
                         toast.success(<div>Copied key! <br /> {record.id} </div>, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        }); 
                    }}>{record.id}</a>
                </Tooltip>
            },
        },
        {
            title: lang.expired_at || 'Hết hoạt động lúc',
            dataIndex: 'end_date',
            key: 'end_date',
        },
        {
            title: lang.status || 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => status ? "Đang hoạt động" : "Hết hạn"
        },
        {
            title: lang.expiry || 'Thời hạn',
            dataIndex: 'day_left',
            key: 'day_left',
            render: (_, record) => {
                if(record.day_left){
                    return <p>{record.day_left} {lang.day_left}</p>
                }
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => onClickView(record)}>View</a>
                    <a onClick={() => onDelete(record.id)}>Delete</a>
                </Space>
            ),
        }
    ];

    return <>
        <Table
            rowKey={'id'}
            columns={columnsData}
            dataSource={dataFilter}
        />
        <ModalViewKey
            keyView={keyView}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            lang={lang}
        />
    </>
};

export default TableComponent;