export const Statistic = ({statistic, langData}) => {
    return (
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
            <div className="grid grid-cols-2 row-gap-8 md:grid-cols-4">
                <div className="text-center md:border-r">
                    <h6 className="text-4xl font-bold lg:text-4xl xl:text-4xl">{statistic.total_ip}</h6>
                    <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                        {langData.dashboard_lang.total_ip || <span>Tổng số IP</span>}
                    </p>
                </div>
                <div className="text-center md:border-r">
                    <h6 className="text-4xl font-bold lg:text-4xl xl:text-4xl">{statistic.total_user}</h6>
                    <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                        {langData.dashboard_lang.total_user || <span>Người dùng</span>}
                    </p>
                </div>
                <div className="text-center md:border-r">
                    <h6 className="text-4xl font-bold lg:text-4xl xl:text-4xl">{statistic.trust}</h6>
                    <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                        {langData.dashboard_lang.trust || <span>Độ tin cậy</span>}
                    </p>
                </div>
                <div className="text-center">
                    <h6 className="text-4xl font-bold lg:text-4xl xl:text-4xl">{statistic.support_time}</h6>
                    <p className="text-sm font-medium tracking-widest text-gray-800 uppercase lg:text-base">
                        {langData.dashboard_lang.support_time || <span>Hỗ trợ</span>}
                    </p>
                </div>
            </div>
        </div>
    );
};