import { useEffect } from "react"
import AuthCMS from "../../Layouts/AuthCMS"
import { getTopupState, onTopupsRequest } from "../../Stores/TopupSlice"
import { useDispatch, useSelector } from "react-redux"
import { getBankInfo, getLangModule } from "../../Stores/AuthSlice"

const Topup = () => {
    const data = useSelector(getTopupState)
    const dataBank = useSelector(getBankInfo)
    const langData = useSelector(getLangModule);
    const { topup: lang } = langData;

    const dispath = useDispatch();
    useEffect(() => {
        dispath(onTopupsRequest());
        const intervalId = setInterval(() => {
            dispath(onTopupsRequest());
        }, 5 * 1000);

        // Cleanup function
        return () => {
            clearInterval(intervalId);
        };
    }, [dispath])

    return <AuthCMS tabActive={"/topup"}>
        <div className="flex flex-col md:flex-row bg-gray-50">
            {/* Left Section - Account Information */}
            <div className="w-full md:w-1/2 bg-white p-6">
                <h2 className="text-lg font-semibold mb-4">{lang.title || "Thông tin nạp tài khoản"}</h2>
                <div className="mb-4">
                    <span className="text-gray-500">{lang.bank || "Ngân hàng"}</span>
                    <p className="text-red-500 font-bold">{dataBank.name}</p>
                </div>
                <div className="mb-4">
                    <span className="text-gray-500">{lang.account_name || "Chủ tài khoản"}</span>
                    <p className="text-red-500 font-bold">{dataBank.account_name}</p>
                </div>
                <div className="mb-4">
                    <span className="text-gray-500">{lang.account_number || "Số tài khoản"}</span>
                    <p className="text-red-500 font-bold">{dataBank.account_number}</p>
                </div>
                <div className="mb-4">
                    <span className="text-gray-500">{lang.tranction_detail || "Nội dung giao dịch"}</span>
                    <p className="text-red-500 font-bold">{data.transaction_content}</p>
                </div>
            </div>

            {/* Right Section - QR Code and Instructions */}
            <div className="w-full md:w-1/2 bg-white p-6">
                <h2 className="text-lg font-semibold mb-4">{lang.topup_qrcode || "Nạp tài khoản qua mã QR"}</h2>
                <div className="flex justify-center mb-4">
                    <img
                        src={data.qr_code}
                        alt="VietQR"
                        className="w-32 h-32"
                    />
                </div>
                <h3 className="font-bold text-center mb-4">{lang.guide_topup || "Hướng dẫn nạp tài khoản qua mã QR"}</h3>
                <ol className="text-sm list-decimal list-inside space-y-2" dangerouslySetInnerHTML={{__html:lang.topup_description}}>
                    {/* <li>
                        Đăng nhập ứng dụng Mobile Banking, chọn chức năng Scan QR và quét mã
                        trên đây.
                    </li>
                    <li>
                        Nhập số tiền muốn nạp, kiểm tra thông tin đơn hàng (NH, chủ TK, số
                        TK, nội dung CK) trùng khớp với thông tin tài khoản bên trái.
                    </li>
                    <li>Xác nhận thanh toán và hoàn tất giao dịch.</li> */}
                </ol>
                <p className="text-xs text-red-500 mt-4">
                    *Chú ý: mỗi mã QR chỉ dùng cho 1 giao dịch nạp tiền, không sử dụng lại
                </p>
            </div>
        </div>
    </AuthCMS>
}

export default Topup