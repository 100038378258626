import axios from "axios"
import { getToken } from "./AuthSlice"
const HOST = process.env.REACT_APP_API_HOST

export const topupsFetch = async (token) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/topups/", {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}