import { Button } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthCMS from "../../Layouts/AuthCMS";
import { getCyclesRequest, getCyclesState } from "../../Stores/CycleSlice";
import { getKeysRequest, getKeysState } from "../../Stores/KeySlice";
import SelectMulti from "./Components/SelectMulti";
import TableComponent from "./Components/Table";
import { getPackagesRequest, getPackagesState } from "../../Stores/PackageSlice";
import ModalExportAll from "./Components/Modal/ExportAll";
import { getLangModule } from "../../Stores/AuthSlice";

const Key = () => {
    const keyData = useSelector(getKeysState);
    const cyclesData = useSelector(getCyclesState);
    const packagesData = useSelector(getPackagesState);
    const langData = useSelector(getLangModule);
    const { key: lang } = langData;
    const dispatch = useDispatch();

    const [cyclesFillterData, setCyclesFillterData] = useState([]);
    const [statusFillterData, setStatusFillterData] = useState([]);
    const [searchData, setSearchData] = useState([]);

    // Modal ExportAll 
    const [openModalExportAll, setOpenModalExportAll] = useState(false);

    useEffect(() => {
        dispatch(getKeysRequest());
        dispatch(getCyclesRequest());
        dispatch(getPackagesRequest());
    }, [dispatch]);

    const onFillterPackage = (selected) => {
        let cyclesFillter = [...cyclesData];
        cyclesFillter = cyclesFillter.filter(c => selected.some(s => s.id === c.package_id))
        setCyclesFillterData(cyclesFillter);
    }

    const onChangeSearch = (e) => {
        setSearchData(e.target.value)
    }

    const onExportAll = (e) => {
        setOpenModalExportAll(true)
    }

    return (
        <AuthCMS tabActive={"/key"}>
            <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
                <div>
                    <h3 className="text-lg font-bold text-slate-800">{lang.title || 'Danh sách Key'}</h3>
                    <p className="text-slate-500">Key của bạn</p>
                </div>
                <div className="mr-3">
                    <div className="w-full max-w-sm min-w-[200px] relative">
                    </div>
                </div>
            </div>

            <div className="flex border-t flex-wrap justify-between">
                <div className="flex flex-wrap">
                    <div className="relative p-2 w-60">
                        <input
                            className="bg-white w-full pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
                            placeholder="Search for key..."
                            onChange={onChangeSearch}
                        />
                        <button
                            className="absolute h-8 w-8 right-3 top-3 my-auto px-2 flex items-center bg-white rounded "
                            type="button"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-8 h-8 text-slate-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                        </button>
                    </div>

                    <SelectMulti
                        placeholder={lang.status || "Tình trạng Key"}
                        options={[
                            { id: 1, name: "Còn hiệu lực", status: true },
                            { id: 2, name: "Hết hạn", status: false },
                        ]}
                        setData={setStatusFillterData}
                    />

                    <SelectMulti
                        placeholder={lang.package || "Gói"}
                        options={packagesData}
                        setData={onFillterPackage}
                    />
                </div>
                <div className="p-2 pr-8">
                    <Button onClick={onExportAll} className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
                        {lang.export_all || "Xuất Tất Cả"}
                    </Button>
                </div>
            </div>

            <TableComponent 
                keyData={keyData}
                cyclesData={cyclesData}
                cyclesFillterData={cyclesFillterData}
                statusFillterData={statusFillterData}
                searchData={searchData}
                lang={lang}
            />
            <ModalExportAll
                isOpen={openModalExportAll}
                setIsOpen={setOpenModalExportAll}
                keyData={keyData}
            />
        </AuthCMS>
    );
};

export default Key;
