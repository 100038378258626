import { useDispatch, useSelector } from "react-redux"
import AuthCMS from "../../Layouts/AuthCMS"
import TableUsers from "./Components/TableUsers"
import { useEffect } from "react"
import { getListUsersRequest, getListUserState } from "../../Stores/UserSlice"

const Users = () => {
    const users = useSelector(getListUserState)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getListUsersRequest())
    }, [dispatch])

    return <AuthCMS tabActive={"/users"}>
        <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
            <div>
                <h3 className="text-lg font-bold text-slate-800">{'Users'}</h3>
                <p className="text-slate-500">Danh sách người dùng</p>
            </div>
            <div className="mr-3">
                <div className="w-full max-w-sm min-w-[200px] relative">
                </div>
            </div>
        </div>
        <TableUsers
            data={users}
        />
    </AuthCMS>
}

export default Users