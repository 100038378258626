import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailKeyRequest, getKeyState, getKeyStatusState, refreshKeysRequest } from '../../../../Stores/KeySlice';
const ModalViewKey = ({ keyView, isModalOpen, setIsModalOpen, lang }) => {
    const keyDetail = useSelector(getKeyState);
    const keyStatus = useSelector(getKeyStatusState);
    const dispatch = useDispatch();

    const [disableRefreshBtn, setDisableRefreshBtn] = useState(true);
    const [timeRemainUsage, setTimeRemainUsage] = useState("");
    const [timeRemainMinimumUsage, setTimeRemainMinimumUsage] = useState("");

    useEffect(() => {
        // Read Key
        if (isModalOpen) {
            dispatch(getDetailKeyRequest(keyView.id));
        }
    }, [isModalOpen])

    useEffect(() => {
        if (keyDetail.id) {
            if (keyDetail.remain_time_usage > 0) {
                window.intervalRemainUsage = countdownTimer(keyDetail.remain_time_usage, setTimeRemainUsage)
            }
            if (keyDetail.remain_minumum_usage > 0) {
                window.intervalRemainMinimumUsage = countdownTimer(keyDetail.remain_minumum_usage, setTimeRemainMinimumUsage)
            } else {
                setDisableRefreshBtn(false)
            }
        }

    }, [keyDetail])

    const afterCloseHandle = () => {
        clearInterval(window.intervalRemainUsage)
        clearInterval(window.intervalRemainMinimumUsage)
        setTimeRemainUsage("")
        setTimeRemainMinimumUsage("")
        setDisableRefreshBtn(true)
    }

    const handleGetProxy = () => {
        window.intervalRemainUsage && clearInterval(window.intervalRemainUsage)
        window.intervalRemainMinimumUsage && clearInterval(window.intervalRemainMinimumUsage)
        dispatch(refreshKeysRequest(keyView.id));
        setDisableRefreshBtn(true)
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    function countdownTimer(durationInSeconds, setResult) {
        let remainingTime = durationInSeconds;

        const timerInterval = setInterval(() => {
            const minutes = Math.floor(remainingTime / 60);
            const seconds = remainingTime % 60;

            const formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
            setResult(formattedTime)
            remainingTime--;

            if (remainingTime < 0) {
                clearInterval(timerInterval);
                setDisableRefreshBtn(false)
            }
        }, 1000);

        return timerInterval
    }


    return (
        <>
            <Modal
                title="Thông tin Proxy"
                open={isModalOpen}
                onCancel={handleCancel}
                destroyOnClose={true}
                afterClose={afterCloseHandle}
                footer={[
                    <Button key={1} type="primary" onClick={handleGetProxy} disabled={disableRefreshBtn}>
                        {lang.get_proxy || "Lấy Proxy"}
                    </Button>,
                    <Button key={2} onClick={handleCancel}>
                        {lang.close || "Đóng"}
                    </Button>
                ]}
            >
                <Spin spinning={keyStatus == 'loading' ? true : false}>
                    <p>{lang.status || "Trạng thái"}: {keyDetail.status ? "Đang hoạt động" : "Dừng hoạt động"}</p>
                    <p>Địa chỉ IP: {keyDetail.ip}</p>
                    <p>HTTP: {keyDetail.port}</p>
                    <p>SOCKS5: {keyDetail.socks5}</p>
                    <p>Địa điểm: {keyDetail.city_name}</p>
                    <p>{lang.time_to_live_of_proxy || "Thời gian sống của proxy"}: {timeRemainUsage}</p>
                    <p>{lang.time_count_down_until_expired || "Thời gian đếm ngược đến lượt đổi tiếp theo"}: {timeRemainMinimumUsage}</p>
                </Spin>
            </Modal>
        </>
    );
};
export default ModalViewKey;