import axios from "axios"
const HOST = process.env.REACT_APP_API_HOST

export const updateUserMeFetch = async (token, dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.patch(HOST + "/api/v1/users/me", dataInput, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getUserMeFetch = async (token) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/users/me", {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const updatePasswordMeFetch = async (token, dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.patch(HOST + "/api/v1/users/me/password", dataInput, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getListUserFetch = async (token) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/users/", {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}