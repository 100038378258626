import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthCMS from "../../Layouts/AuthCMS";
import { getCyclesRequest } from "../../Stores/CycleSlice";
import { getPackagesRequest, getPackagesState } from "../../Stores/PackageSlice";
import ModalPackage from "./Components/ModalPackage";
import { getLangModule } from "../../Stores/AuthSlice";

const Packages = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [packageSelected, setPackageSelected] = useState("");
    const packageData = useSelector(getPackagesState);
    const langData = useSelector(getLangModule);
    const { package: lang } = langData;
    const dispath = useDispatch();

    useEffect(() => {
        dispath(getPackagesRequest());
        dispath(getCyclesRequest());
    }, [dispath])

    const onClickBuy = (id) => {
        setPackageSelected(id)
        setIsModalOpen(true)
    }

    return (<AuthCMS tabActive="/package">
        <div className="px-4 py-16 mx-auto sm:w-full md:w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                <div>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                        Danh sách gói
                    </p>
                </div>
                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
                    <span className="relative inline-block">
                        <span className="relative">{lang.title || <span>Proxy Xoay</span>}</span>
                    </span>{' '}
                </h2>
                <p className="text-base text-gray-700 md:text-lg">

                </p>
            </div>
            <div className="grid gap-5 row-gap-5 sm:row-gap-10 lg:grid-cols-2 sm:mx-auto">
                {packageData.map((data) => {
                    return <div key={data.name} className="flex flex-col justify-between p-4 transition-shadow duration-300 bg-white border rounded shadow-sm hover:shadow">
                        <div className="">
                            <div className="text-lg font-semibold text-center">{data.name}</div>
                            <div className="flex items-center justify-center mt-2">
                                <div className="mr-1 text-2xl font-bold text-green-800 text-center">{data.text_price_discount}</div>
                            </div>
                            <div className="mt-2 space-y-3" dangerouslySetInnerHTML={{ __html: data.content }}>
                                {/* <div className="text-gray-700">○ Đổi không giới hạn</div>
                                <div className="text-gray-700">○ Thời gian sử dụng: 20 Phút / Proxy</div>
                                <div className="text-gray-700">○ Sử dụng tối thiểu: 2 Phút / Proxy</div>
                                <div className="text-gray-700">○ Hỗ trợ HTTP(S), SOCKS5</div>
                                <div className="text-gray-700">○ Địa chỉ IPv4</div> */}
                            </div>
                        </div>
                        <div>
                            <button onClick={() => onClickBuy(data.id)} className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-emerald-600 rounded shadow-md hover:bg-emerald-700 focus:shadow-outline focus:outline-none">
                                { lang.buy_now || <span>Mua Ngay</span>}
                            </button>
                        </div>
                    </div>
                })}
            </div>
            <ModalPackage isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} id={packageSelected} lang={lang} />
        </div>
    </AuthCMS>)
}

export default Packages