import axios from "axios"
const HOST = process.env.REACT_APP_API_HOST

export const getVoucherFetch = async (token, dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/vouchers/" + dataInput, {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}