import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getListUserFetch, getUserMeFetch, updatePasswordMeFetch, updateUserMeFetch } from "./UserAPI";
import { toast } from "react-toastify";

const initialState = {
    users: [],
    me: {},
    status: 'idle',
}

export const getUserMeRequest = createAsyncThunk(
    'user/me',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getUserMeFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const updateUserMeRequest = createAsyncThunk(
    'user/update',
    async (data, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await updateUserMeFetch(token, data);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const updatePasswordMeRequest = createAsyncThunk(
    'user/me/update_password',
    async (data, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await updatePasswordMeFetch(token, data);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const getListUsersRequest = createAsyncThunk(
    'user/getlist',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getListUserFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserMeRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getUserMeRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { full_name, email, phone_number, is_superuser } = action.payload
                state.me.full_name = full_name
                state.me.email = email
                state.me.phone_number = phone_number
                state.me.is_superuser = is_superuser
            })
            .addCase(updateUserMeRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserMeRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                toast.success("Update success.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .addCase(updatePasswordMeRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updatePasswordMeRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                toast.success(action.payload.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .addCase(getListUsersRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getListUsersRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.users = data
            })
    }
})

export const getUserMeState = (state) => state.user.me
export const getUserStatusState = (state) => state.user.status
export const getListUserState = (state) => state.user.users

export default UserSlice.reducer