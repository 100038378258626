import { Space, Table } from "antd"

const TableTokens = ({data}) => {
    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 200,
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Token',
            dataIndex: 'value',
            key: 'value',
            width: 240,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 200,
        },
        {
            title: 'Action',
            key: 'action',
            width: 200,
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => onDelete(record.id)}>Delete</a>
                </Space>
            ),
        }
    ]

    const onDelete = () => {
        return
    }

    return (<>
        <Table
            rowKey={'id'}
            columns={columns}
            dataSource={data}
        />
    </>)
}

export default TableTokens