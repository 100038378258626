import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getVoucherFetch } from "./VoucherAPI";
import { toast } from "react-toastify";

const initialState = {
    status: 'idle',
    voucher: {},
}

export const getVoucherRequest = createAsyncThunk(
    'voucher/get',
    async (code, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getVoucherFetch(token, code);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const voucherSlice = createSlice({
    name: 'voucher',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getVoucherRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getVoucherRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { value, type } = action.payload
                state.voucher.value = value
                state.voucher.type = type
                if(type == "percent") {
                    toast.success("Áp dụng mã giảm giá "+value+"%", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
    }
})

export const getVoucherState = (state) => state.voucher.voucher

export default voucherSlice.reducer