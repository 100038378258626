import { useDispatch, useSelector } from "react-redux";
import AuthCMS from "../../Layouts/AuthCMS"
import TablePayment from "./Components/Table"
import { useEffect, useState } from "react";
import { getPaymentsRequest, getPaymentsState } from "../../Stores/PaymentSlice";
import SelectMulti from "./Components/SelectMulti";
import Datepicker from "react-tailwindcss-datepicker";
import { getLangModule } from "../../Stores/AuthSlice";

const Payment = () => {
    const paymentData = useSelector(getPaymentsState);
    const langData = useSelector(getLangModule);
    const { payment: lang } = langData;
    const dispath = useDispatch();
    
    const [statusFillterData, setStatusFillterData] = useState([]);
    const [typeFillterData, setTypeFillterData] = useState([]);
    const [dateRangeFilterData, setDateRangeFilterData] = useState({
        startDate: null,
        endDate: null
    });
    
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
        dispath(getPaymentsRequest());
    }, [dispath])

    const onChangeSearch = (e) => {
        setSearchData(e.target.value)
    }

    const onChangeDateRange = (newValue) => {
        setDateRangeFilterData({
            startDate: new Date(newValue.startDate.setHours(0,0,0,0)),
            endDate: new Date(newValue.endDate.setHours(23,59,59,0)),
        })
    }
    
    return <AuthCMS tabActive={"/payment"}>
        <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
            <div>
                <h3 className="text-lg font-bold text-slate-800">{lang.title || "Lịch sử giao dịch"}</h3>
                <p className="text-slate-500">Payment History</p>
            </div>
            <div className="ml-3">
                <div className="w-full max-w-sm min-w-[200px] relative">
                </div>
            </div>
        </div>
        <div className="flex border-t wrap justify-between">
            <div className="flex">
                <div className="relative p-2 w-60">
                    <input
                        className="bg-white w-full pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
                        placeholder="Search for key..."
                        onChange={onChangeSearch}
                    />
                    <button
                        className="absolute h-8 w-8 right-3 top-3 my-auto px-2 flex items-center bg-white rounded "
                        type="button"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-8 h-8 text-slate-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </button>
                </div>

                <SelectMulti
                    placeholder={lang.transaction_status || "Trạng thái giao dịch"}
                    options={[
                        { id: 1, name: 'Thành công', status: "success" },
                        { id: 2, name: 'Thất bại', status: "false" },
                    ]}
                    setData={setStatusFillterData}
                />

                <SelectMulti
                    placeholder={lang.transaction_type || "Loại giao dịch"}
                    options={[
                        { id: 1, name: 'In', value: "in" },
                        { id: 2, name: 'Out', value: "out" },
                    ]}
                    setData={setTypeFillterData}
                />

                <div className="p-2 w-60">
                    <Datepicker
                        useRange={false}
                        value={dateRangeFilterData}
                        onChange={onChangeDateRange}
                        inputClassName="relative block w-full rounded-lg border py-1.5 pl-3 text-left text-sm/6 text-black/60" 
                        toggleClassName="hidden"
                        placeholder="Ngày bắt đầu - ngày kết thúc"
                    />
                </div>
            </div>
        </div>

        <TablePayment 
            dataPayment={paymentData} 
            statusFillterData={statusFillterData} 
            typeFillterData={typeFillterData}
            dateRangeFilterData={dateRangeFilterData}
            searchData={searchData}
            lang={lang}
        />
    </AuthCMS>
}

export default Payment