import { Button, Col, Form, Input, Modal, Row } from "antd"

const ModalViewUser = ({ isModalOpen, setIsModalOpen }) => {
    const [form] = Form.useForm();
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const afterCloseHandle = () => {

    }

    return <Modal
        title="User View/Update"
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
        afterClose={afterCloseHandle}
        footer={[
            <Button key={1} type="primary">
                Update
            </Button>,
            <Button key={2} onClick={handleCancel}>
                {"Đóng"}
            </Button>
        ]}
    >
        <Form
            layout="vertical"
            form={form}
        >
            <p className="font-bold text-xl pb-4">Chi tiết tài khoản</p>
            <Row gutter={[16, 8]}>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label={"Tên người dùng"}
                        name="username"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label={"Họ và tên"}
                        name="full_name"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label={"Email"}
                        name="email"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label={"Giới tính"}
                        name="gender"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label={"Số điện thoại"}
                        name="phone_number"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                    <Form.Item
                        label={"Ngày sinh"}
                        name="dob"
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                    <Form.Item
                        label={"Mô tả"}
                        name="description"
                    >
                        <Input.TextArea
                            rows={5}
                        ></Input.TextArea>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
}

export default ModalViewUser