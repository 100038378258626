import { Table } from "antd"

const columns = [
    {
        title: 'Trình duyệt',
        dataIndex: 'agent',
        key: 'agent',
        render: (_, record) => {
            return <p>{record.agent.browser} on {record.agent.os}</p>
        }
    },
    {
        title: 'Thời gian hoạt động',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Địa chỉ IP',
        dataIndex: 'ip',
        key: 'ip',
    }
];

const TableActivity = ({dataSource}) => {
    return (<div>
        <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
            <div>
                <h3 className="text-lg font-semibold text-slate-800">Lịch sử đặng nhập</h3>
                <p className="text-slate-500">Danh sách thiết bị đặng nhập</p>
            </div>
            <div className="ml-3">
                <div className="w-full max-w-sm min-w-[200px] relative">
                    <div className="relative">
                        <input
                            className="bg-white w-full pr-11 h-10 pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-200 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
                            placeholder="Tìm kiếm"
                        />
                        <button
                            className="absolute h-8 w-8 right-1 top-1 my-auto px-2 flex items-center bg-white rounded "
                            type="button"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-8 h-8 text-slate-600">
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="relative flex flex-col w-full overflow-scroll text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
            <Table 
                rowKey={"id"}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    defaultPageSize: 5
                }}
            />
        </div>
    </div>)
}

export default TableActivity