import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { onVerifyEmailRequest } from "../../../Stores/AuthSlice";

const Verify = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const dispatch = useDispatch();
    const [countdown, setCountdown] = useState(-1)
    const [error, setError] = useState(0)

    useEffect(() => {
        if (token) {
            dispatch(onVerifyEmailRequest(token))
                .then((response) => {
                    console.log(response);
                    if (response.payload == false) {
                        setError(1)
                    } else if (response.payload.message == "Verify successfully") {
                        setCountdown(5)
                    }
                })
                .catch((error) => {
                    console.error(error)
                });
        }
    }, [token, dispatch]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            if (countdown == 0) {
                window.location.href = '/login'; // redirect when countdown reaches 0
            }
        }
    }, [countdown]);

    return (<div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="mx-auto h-10 w-auto flex justify-center">
                <svg className="w-10 h-10 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                    <path
                        fillRule="evenodd"
                        d="M11.757 2.034a1 1 0 01.638.519c.483.967.844 1.554 1.207 2.03.368.482.756.876 1.348 1.467A6.985 6.985 0 0117 11a7.002 7.002 0 01-14 0c0-1.79.684-3.583 2.05-4.95a1 1 0 011.707.707c0 1.12.07 1.973.398 2.654.18.374.461.74.945 1.067.116-1.061.328-2.354.614-3.58.225-.966.505-1.93.839-2.734.167-.403.356-.785.57-1.116.208-.322.476-.649.822-.88a1 1 0 01.812-.134zm.364 13.087A2.998 2.998 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879.586.585.879 1.353.879 2.121s-.293 1.536-.879 2.121z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Verify email {countdown == -1 ? "" : "sucessfully"} <br />Proxy Connector Pro
            </h2>
        </div>
        {
            error ? <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">
                <p>Token không hợp lệ.</p>
            </div>
                : <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm text-center">
                    <p>Bạn sẽ được chuyển đến trang login trong {countdown}..</p>
                </div>
        }
    </div>)
}

export default Verify