import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

const SelectMulti = ({
    placeholder = "Select..",
    options = [],
    setData,
}) => {
    const [selected, setSelected] = useState([])

    useEffect(() => {
        setData(selected)
    }, [selected])

    const toggleSelect = (option) => {
        if (selected.some((item) => item.id === option.id)) {
            setSelected(selected.filter((item) => item.id !== option.id))
        } else {
            const newSelected = [...selected]
            newSelected.push(option)
            setSelected(newSelected)
        }
    }

    return (
        <div className="p-2 w-60">
            <Listbox value={selected} multiple>
                <ListboxButton
                    className={clsx(
                        'relative block w-full rounded-lg border py-1.5 pr-8 pl-3 text-left text-sm/6 text-black/60',
                        'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                    )}
                >
                    {selected.length > 0 ? selected.map((person) => person.name).join(', ') : placeholder}
                    <ChevronDownIcon
                        className="group pointer-events-none absolute top-2.5 right-2.5 size-4 fill-black/60"
                        aria-hidden="true"
                    />
                </ListboxButton>
                <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                        'z-10 w-[var(--button-width)] rounded-xl border bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none',
                        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
                    )}
                >
                    {options.map((option) => (
                        <ListboxOption
                            key={option.name}
                            value={option}
                            onClick={() => toggleSelect(option)}
                            className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-black/10"
                        >
                            <CheckIcon className={clsx(
                                'size-4 fill-black',
                                { 'invisible': !selected.some((item) => item.id === option.id) },
                                { 'visible': selected.some((item) => item.id === option.id) }
                            )} />
                            <div className="text-sm/6 text-black/60">{option.name}</div>
                        </ListboxOption>
                    ))}
                </ListboxOptions>
            </Listbox>
        </div>
    )
}

export default SelectMulti