import { Tabs } from "antd";
import AuthCMS from "../../Layouts/AuthCMS";
import Account from "./Tabs/Account";
import Privacy from "./Tabs/Privacy";
import Tokens from "./Tabs/Tokens";

const Me = () => {
    const items = [
        {
            key: '1',
            label: 'Tài khoản',
            children: <Account />,
        },
        {
            key: '2',
            label: 'Bảo mật',
            children: <Privacy/>,
        },
        {
            key: '3',
            label: 'API Token',
            children: <Tokens/>,
        },
    ];
    return <AuthCMS tabActive={"/me"}>
        <Tabs defaultActiveKey="1" items={items} style={{padding:8}}/>
    </AuthCMS>
}

export default Me