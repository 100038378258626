import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getIsAuthenticated, getIsReady, onGetSettingsRequest } from "../../Stores/AuthSlice";
import { useEffect } from "react";
import LoadingScreen from "../../Layouts/LoadingSceen";
import { getUserMeRequest } from "../../Stores/UserSlice";

const Auth = ({ component: Component }) => {
    const isAuthenticated = useSelector(getIsAuthenticated)
    const isReady = useSelector(getIsReady)
    const dispatch = useDispatch()
    if(!isAuthenticated) {
        return <Navigate to="/login" replace={true} />
    }
    if(!Component) {
        return <Navigate to="/dashboard" replace={true} />
    }

    if(!isReady) {
        dispatch(onGetSettingsRequest())
        dispatch(getUserMeRequest())
    }

    return isReady ? <Component /> : <LoadingScreen />;
}

export default Auth