import { Table } from "antd"
import { useEffect, useState } from "react";


const TablePayment = ({ dataPayment, statusFillterData, typeFillterData, dateRangeFilterData, searchData, lang }) => {
    const [data, setData] = useState([])
    const [dataFilter, setDataFilter] = useState([])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: lang.transaction_date || 'Ngày Giao Dịch',
            dataIndex: 'payment_time',
            key: 'payment_time',
            render: (text) => <p>{text}</p>,
        },
        // {
        //     title: 'Nội dung giao dịch',
        //     dataIndex: 'cycle',
        //     key: 'cycle',
        //     render: (_, record) => {
        //         return record.cycle_data && <p>{record.cycle_data.name}</p>
        //     },
        // },
        {
            title: lang.transaction_type || 'Loại giao dịch',
            dataIndex: 'payment_type',
            key: 'payment_type',
        },
        {
            title: lang.amount || 'Số lượng',
            dataIndex: 'payment_amount',
            key: 'payment_amount',
        },
        {
            title: lang.before_balance || 'Số dư trước',
            dataIndex: 'previous_balance',
            key: 'previous_balance',
        },
        {
            title: lang.after_balance || 'Số dư sau',
            dataIndex: 'next_balance',
            key: 'next_balance',
        },
        {
            title: lang.transaction_status || 'Trạng thái giao dịch',
            dataIndex: 'payment_status',
            key: 'payment_status',
        },
    ];

    useEffect(() => {
        setData(dataPayment)
        setDataFilter(dataPayment)
    }, [dataPayment])

    useEffect(() => {
        // Filter
        let updatedData = [...data]

        statusFillterData.map((filter) => {
            updatedData = updatedData.filter((payment) => {
                if (payment.payment_status == filter.status) {
                    return true
                }
            })
        })

        typeFillterData.map((filter) => {
            updatedData = updatedData.filter((payment) => {
                if (payment.payment_type == filter.value) {
                    return true
                }
            })
        })

        // Daterange
        updatedData = updatedData.filter((payment) => {
            const dateFormat = new Date(payment.payment_time)
            if (dateRangeFilterData.endDate) {
                const isWithinRange =
                    dateRangeFilterData.startDate <= dateFormat &&
                    dateFormat <= dateRangeFilterData.endDate;
                console.log(dateRangeFilterData.startDate <= dateFormat)
                return isWithinRange
            }

            if (!dateRangeFilterData.endDate) {
                return true
            }
        })

        // Search
        updatedData = updatedData.filter((key) => {
            if (searchData && key.payment_time) {
                if (key.payment_time.indexOf(searchData) !== -1) {
                    return true
                }
            }
            if (!searchData) {
                return true
            }
        })

        setDataFilter(updatedData)
    }, [statusFillterData, typeFillterData, dateRangeFilterData, searchData])

    return <>
        <Table
            rowKey={'id'}
            columns={columns}
            dataSource={dataFilter}
        />
    </>
}

export default TablePayment