import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPackagesFetch } from "./PackageAPI";
import { toast } from "react-toastify";

const initialState = {
    packages: []
}

export const getPackagesRequest = createAsyncThunk(
    'packages/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getPackagesFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const PackageSlice = createSlice({
    name: 'packages',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPackagesRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPackagesRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.packages = data
            })
    }
})

export const getPackagesState = (state) => state.packages.packages

export default PackageSlice.reducer