import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom'
import { getBalance, getLang, getMeInfo, getSupport, signOut } from '../../Stores/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import WalletIcon from './Icon/Wallet';
import LanguageSelector from './Components/LangButton';
import { FacebookFilled } from '@ant-design/icons';
import FacebookIcon from './Icon/Facebook';
import TelegramIcon from './Icon/Telegram';
const Header = () => {
    const dispatch = useDispatch();
    const meInfo = useSelector(getMeInfo)
    const balance = useSelector(getBalance)
    const support = useSelector(getSupport)
    const lang = useSelector(getLang)
    const navigate = useNavigate()

    const onLogoutEvent = () => {
        dispatch(signOut())
    }

    const onTopup = () => {
        navigate('/topup');
    }

    return (<>
        <header className="header bg-white shadow py-4 px-4 sticky top-0 z-20">
            <div className="header-content flex items-center flex-row">
                <div className="flex whitespace-nowrap">
                    <p>Liên hệ:</p>
                    <div className="w-6 ml-2 cursor-pointer">
                        <a href={support.facebook} target='_blank'>
                            <FacebookIcon />
                        </a>
                    </div>
                    <div className='w-6 ml-2 cursor-pointer'>
                        <a href={support.telegram} target='_blank'>
                            <TelegramIcon />
                        </a>
                    </div>
                </div>
                <div className="flex ml-auto items-center">
                    <button onClick={onTopup} className="h-10 rounded-md border border-slate-300 py-2 px-4 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none" type="button">
                        <div className='flex'>
                            <WalletIcon />
                            <p className='ml-2'>{balance.toLocaleString()} VND</p>
                        </div>
                    </button>
                    <div className='py-2 px-2'>
                        <LanguageSelector lang={lang} />
                    </div>
                    <div className="flex flex-row items-center">
                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <MenuButton className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-1 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                                    <div className="h-10 w-10 bg-gray-200 border rounded-full pl-1.5 py-1">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="#000"
                                            version="1.1"
                                            viewBox="0 0 512 512"
                                            xmlSpace="preserve"
                                        >
                                            <path d="M333.187 237.405c32.761-23.893 54.095-62.561 54.095-106.123C387.282 58.893 328.389 0 256 0S124.718 58.893 124.718 131.282c0 43.562 21.333 82.23 54.095 106.123-81.44 31.165-139.428 110.126-139.428 202.39 0 39.814 32.391 72.205 72.205 72.205h288.82c39.814 0 72.205-32.391 72.205-72.205 0-92.264-57.988-171.225-139.428-202.39zM164.103 131.282c0-50.672 41.225-91.897 91.897-91.897s91.897 41.225 91.897 91.897S306.672 223.18 256 223.18s-91.897-41.226-91.897-91.898zM400.41 472.615H111.59c-18.097 0-32.82-14.723-32.82-32.821 0-97.726 79.504-177.231 177.231-177.231s177.231 79.504 177.231 177.231c-.001 18.098-14.724 32.821-32.822 32.821z"></path>
                                        </svg>
                                    </div>
                                    <span className="truncate font-semibold tracking-wide leading-none">{meInfo.email}</span>
                                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                                </MenuButton>
                            </div>

                            <MenuItems
                                transition
                                className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                                <div className="py-1">
                                    <MenuItem>
                                        <Link
                                            to={"/me"}
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            Account settings
                                        </Link>
                                    </MenuItem>
                                    <MenuItem>
                                        <a
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            Support
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <a
                                            href="#"
                                            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            License
                                        </a>
                                    </MenuItem>
                                    <MenuItem>
                                        <button
                                            onClick={onLogoutEvent}
                                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                        >
                                            Sign out
                                        </button>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </Menu>
                    </div>
                </div>
            </div>
        </header>
    </>)
}

export default Header