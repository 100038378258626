import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changeLanguageFetch, forgetPassFetch, getSettingsFetch, loginFetch, registerFetch, resetPassFetch, verifyEmailFetch } from "./AuthAPI";
import { toast } from "react-toastify";
import { makeMenu } from "../Layouts/AuthCMS/Sidebar";

const initialState = {
    isLogin: localStorage.getItem('accessToken') ? true : false,
    accessToken: localStorage.getItem('accessToken') || "",
    status: 'idle',
    isReady: false,
    me: {
        name: "",
        email: "",
        phone_number: "",
    },
    menu: [],
    balance: 0,
    bank: {},
    lang: "",
    module_lang: {},
    support: {},
};

export const onLoginRequest = createAsyncThunk(
    'auth/login',
    async (data) => {
        const result = {
            access_token: null,
        }
        const response = await loginFetch(data);
        if(response.status === 200) {
            return response.data;
        } else {
            console.log(response)
            toast.error(response.response.data.detail.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return result
        }

    }
)

export const onRegisterRequest = createAsyncThunk(
    'auth/register',
    async (data) => {
        const response = await registerFetch(data);
        if(response.status === 200) {
            return response.data;
        } else {
            console.log(response)
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const onForgetPassRequest = createAsyncThunk(
    'auth/recovery',
    async (email) => {
        const response = await forgetPassFetch(email);
        if(response.status === 200) {
            return response.data;
        } else {
            console.log(response)
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const onResetPassRequest = createAsyncThunk(
    'auth/reset',
    async (data) => {
        const response = await resetPassFetch(data);
        if(response.status === 200) {
            return response.data;
        } else {
            console.log(response)
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const onGetSettingsRequest = createAsyncThunk(
    'auth/settings',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getSettingsFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
    }
)

export const onVerifyEmailRequest = createAsyncThunk(
    'auth/verify-email',
    async (token) => {
        const response = await verifyEmailFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            return false
        }
    }
)

export const onChangeLangRequest = createAsyncThunk(
    'auth/change-lang',
    async (data, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await changeLanguageFetch(token, data);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const AuthSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsLogin: (state) => {
            state.isLogin = true
        },
        signOut: (state) => {
            state.isLogin = false
            state.accessToken = ""
            state.me = {}
            state.balance = 0
            state.bank = {}
            state.isReady = false;
            localStorage.setItem('accessToken', "")
            toast.success('Logout!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(onLoginRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(onLoginRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { access_token } = action.payload
                if (access_token) {
                    state.isLogin = true;
                    state.accessToken = access_token;
                    localStorage.setItem('accessToken', access_token)
                    toast.success('Welcome back!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .addCase(onRegisterRequest.pending, (state) => {
                state.status = 'loading';
            }) 
            .addCase(onRegisterRequest.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(onForgetPassRequest.pending, (state) => {
                state.status = 'loading';
            }) 
            .addCase(onForgetPassRequest.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(onResetPassRequest.pending, (state) => {
                state.status = 'loading';
            }) 
            .addCase(onResetPassRequest.fulfilled, (state, action) => {
                state.status = 'idle';
            })
            .addCase(onGetSettingsRequest.pending, (state) => {
                state.status = 'loading';
            }) 
            .addCase(onGetSettingsRequest.fulfilled, (state, action) => {
                if(action.payload){
                    const { menu, me, balance, bank, language, module_lang, support } = action.payload
                    state.me.email = me.email
                    state.menu = makeMenu(menu)
                    state.status = 'idle';
                    state.balance = balance
                    state.bank = bank
                    state.isReady = true;
                    state.lang = language;
                    state.module_lang = module_lang;
                    state.support = support;
                } else {
                    state.isLogin = false
                    state.accessToken = ""
                    state.status = 'idle';
                    localStorage.setItem('accessToken', "")
                    toast.info('Expired session! Please login again!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .addCase(onChangeLangRequest.pending, (state) => {
                state.status = 'loading';
            }) 
            .addCase(onChangeLangRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { access_token } = action.payload
                const { arg } = action.meta
                if (access_token) {
                    state.isLogin = true;
                    state.accessToken = access_token;
                    state.lang = arg
                    localStorage.setItem('accessToken', access_token)
                    toast.success('Change Language success!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }); 
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
    },
})

export const { setIsLogin, signOut } = AuthSlice.actions

export const getIsAuthenticated = (state) => state.auth.isLogin

export const getStatusAuth = (state) => state.auth.status

export const getIsReady = (state) => state.auth.isReady

export const getMeInfo = (state) => state.auth.me

export const getMenu = (state) => state.auth.menu

export const getBankInfo = (state) => state.auth.bank

export const getBalance = (state) => state.auth.balance

export const getLang = (state) => state.auth.lang

export const getLangModule = (state) => state.auth.module_lang

export const getSupport = (state) => state.auth.support

export default AuthSlice.reducer