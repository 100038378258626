import { Button, Dropdown, Flex, Input, Modal } from "antd"

const ModalExportAll = ({ isOpen, setIsOpen, keyData }) => {
    const handleCancel = () => {
        setIsOpen(false);
    };

    const keys = keyData.map((key) => {
        return key.id
    }).join(',\n')

    const handleDownload = (format) => {
        let fileContent = "";
        let fileType = "text/plain";
        let fileExtension = "txt";

        if (format === "json") {
            // fileContent = JSON.stringify(keyData, null, 2);
            fileType = "application/json";
            fileExtension = "json";
        } else if (format === "csv") {
            // const csvHeaders = Object.keys(keyData[0] || {}).join(",") + "\n";
            // const csvRows = keyData.map((key) => Object.values(key).join(",")).join("\n");
            // fileContent = csvHeaders + csvRows;
            fileType = "text/csv";
            fileExtension = "csv";
        } else {
            // fileContent = keys; // Default to plain text
        }
        fileContent = keys;

        const blob = new Blob([fileContent], { type: fileType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `keys.${fileExtension}`;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    const items = [
        {
            label: <a onClick={() => handleDownload("txt")}>
                Tải xuống TXT
            </a>,
            key: '0',
        },
        {
            label: <a onClick={() => handleDownload("json")}>
                Tải xuống JSON
            </a>,
            key: '1',
        },
        {
            label: <a onClick={() => handleDownload("csv")}>
                Tải xuống CSV
            </a>,
            key: '3',
        },
    ];

    return (<Modal
        title="Xuất Key"
        open={isOpen}
        onCancel={handleCancel}
        footer={<></>}
    >
        <Flex justify="space-between" className="pb-2">
            <p>Danh sách key</p>
            <Dropdown
                menu={{
                    items,
                }}
                trigger={['click']}
            >
                <Button type="primary">Tải xuống</Button>
            </Dropdown>
        </Flex>
        <Input.TextArea
            rows="10"
            value={keys}
        >
        </Input.TextArea>
    </Modal>)
}

export default ModalExportAll