import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCyclesFetch } from "./CycleAPI";

const initialState = {
    cycles: []
}

export const getCyclesRequest = createAsyncThunk(
    'cycles/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getCyclesFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const CycleSlice = createSlice({
    name: 'cycles',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCyclesRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getCyclesRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.cycles = data
            })
    }
})

export const getCyclesState = (state) => state.cycles.cycles

export default CycleSlice.reducer