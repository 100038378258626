import { useEffect, useState } from "react";
import TableTokens from "./Components/TableTokens";
import { useDispatch, useSelector } from "react-redux";
import { getTokenListRequest, getTokenState } from "../../../Stores/TokenSlice";
import { Button } from "antd";
import ModalCreateToken from "./Components/ModalCreateToken";

const Tokens = () => {
    const dataTokens = useSelector(getTokenState);
    const dispatch = useDispatch();
    const [searchData, setSearchData] = useState([]);
    const [isOpenCreateToken, setIsOpenCreateToken] = useState(false);

    useEffect(() => {
        dispatch(getTokenListRequest());
    }, [dispatch])

    const onChangeSearch = (e) => {
        setSearchData(e.target.value)
    }

    const onCreateEvent = () => {
        setIsOpenCreateToken(true)
    }

    return <>
        <div className="w-full flex justify-between items-center mb-3 mt-1 pl-3">
            <div>
                <h3 className="text-lg font-bold text-slate-800">{"API Token"}</h3>
                <p className="text-slate-500">API Token</p>
            </div>
            <div className="mr-3">
                <div className="w-full relative">
                    <Button onClick={onCreateEvent} className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
                        {"+ Tạo mới"}
                    </Button>
                </div>
            </div>
        </div>
        <div className="flex border-t wrap justify-between">
            <div className="flex">
                <div className="relative p-2 w-60">
                    <input
                        className="bg-white w-full pl-3 py-2 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
                        placeholder="Search for key..."
                        onChange={onChangeSearch}
                    />
                    <button
                        className="absolute h-8 w-8 right-3 top-3 my-auto px-2 flex items-center bg-white rounded "
                        type="button"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-8 h-8 text-slate-600">
                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <TableTokens
            data={dataTokens}
            searchData={searchData}
        />

        <ModalCreateToken
            isOpen={isOpenCreateToken}
            setIsOpen={setIsOpenCreateToken}
        />
    </>
}

export default Tokens