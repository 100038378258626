const PackageIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="#000"
            version="1.1"
            viewBox="0 0 32 32"
        >
            <g>
                <g
                    fill="currentColor"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="4.1"
                    transform="translate(-348 -484)"
                >
                    <path
                        d="M355 508.014a1 1 0 00-1 1 1 1 0 001 1h6a1 1 0 001-1 1 1 0 00-1-1z"
                        style={{ InkscapeStroke: "none" }}
                    ></path>
                    <path
                        d="M354 486.014a1 1 0 00-.895.554l-3 6.036a1 1 0 00-.1.421 1 1 0 00-.005.024v19.965a1 1 0 001 1h26a1 1 0 001-1v-19.965a1 1 0 000-.002 1 1 0 00-.106-.444l-3-6.035a1 1 0 00-.894-.554zm.617 2h6.21l-.655 4.035h-7.559zm8.233 0h4.302l.674 4.035h-5.629zm6.33 0h4.2l2.007 4.035h-5.533zM352 494.049h8v9.965a1 1 0 001.707.707l3.293-3.293 3.293 3.293a1 1 0 001.707-.707v-9.965h6v17.965h-24zm10 0h6v7.55l-2.293-2.292a1 1 0 00-1.414 0L362 501.6z"
                        style={{ InkscapeStroke: "none" }}
                    ></path>
                </g>
            </g>
        </svg>
    )
}

export default PackageIcon