import axios from "axios"
const HOST = process.env.REACT_APP_API_HOST
export const loginFetch = async ({ username, password }) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.post(HOST + "/api/v1/login/access-token", {
                username,
                password
            }, {headers: {'content-type': 'application/x-www-form-urlencoded'}})
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const registerFetch = async (dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.post(HOST + "/api/v1/users/signup", dataInput)
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const forgetPassFetch = async (email) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.post(HOST + "/api/v1/password-recovery/" + encodeURIComponent(email))
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const resetPassFetch = async (dataInput) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.post(HOST + "/api/v1/reset-password/", dataInput)
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const getSettingsFetch = async (token) => {
    return new Promise(async (resolve) => {
        let data = []
        try {
            data = await axios.get(HOST + "/api/v1/settings/", {
                headers: { Authorization: `Bearer ${token}` }
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const verifyEmailFetch = async (token) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/users/verify/", {
                params: {
                    token
                },
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}

export const changeLanguageFetch = async (token, lang) => {
    return new Promise(async (resolve) => {
        let data = {}
        try {
            data = await axios.get(HOST + "/api/v1/users/change-language/", {
                headers: { Authorization: `Bearer ${token}` },
                params: {
                    lang
                },
            })
        } catch (err) {
            data = err
        }
        resolve(data)
    })
}