const TopupIcon = () => {
    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0"
        version="1.1"
        viewBox="0 0 24 24"
        xmlSpace="preserve"
    >
        <g>
            <path d="M17.9 2.6c-1-1.1-2.7-1.6-5-1.6H6.3c-.5 0-.9.3-.9.8L2.6 19c-.1.3.2.7.6.7h4.1l1-6.3h0c.1-.5.5-.8.9-.8h1.9c3.8 0 6.7-1.5 7.6-6 0-.1.1-.3.1-.4.2-1.5 0-2.6-.9-3.6m1.8 4.6h0c0 .1-.1.2-.1.4-.9 4.4-3.8 6-7.6 6h-1.9c-.5 0-.8.3-.9.8l-1 6.3-.3 1.8c0 .3.2.6.5.6h3.4c.4 0 .7-.3.8-.7v-.2l.6-4.1v-.2c.1-.4.4-.7.8-.7h.6c3.3 0 5.9-1.3 6.7-5.2.3-1.6.2-3-.7-3.9-.3-.4-.6-.7-.9-.9"></path>
        </g>
    </svg>)
}

export default TopupIcon