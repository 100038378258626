import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { LanguageIcon } from '@heroicons/react/20/solid'
import { useDispatch } from 'react-redux';
import { onChangeLangRequest } from '../../../Stores/AuthSlice';
const LanguageSelector = ({ lang }) => {
    const dispatch = useDispatch();
    const handleLanguageChange = (language) => {
        dispatch(onChangeLangRequest(language))
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <MenuButton className="h-10 w-16 inline-flex justify-center items-center gap-x-1.5 border-solid border border-slate-300 rounded-full px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                    <LanguageIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                    <p>{lang.toUpperCase()}</p>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    <MenuItem>
                        <button
                            onClick={() => handleLanguageChange('vn')}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            Tiếng Việt
                        </button>
                    </MenuItem>
                    <MenuItem>
                        <button
                            onClick={() => handleLanguageChange('en')}
                            className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            English
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </Menu>
    );
};

export default LanguageSelector;
