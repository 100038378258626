import { render } from "@testing-library/react"
import { Space, Table } from "antd"
import { CheckCircleTwoTone } from '@ant-design/icons';
import ModalViewUser from "./ModalViewUser";
import { useState } from "react";

const TableUsers = ({ data }) => {
    const [isOpenViewUser, setIsOpenViewUser] = useState(false)
    const [dataUser, setDataUser] = useState({})

    const columns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 200,
        //     fixed: 'left',
        // },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
        },
        {
            title: 'Is active',
            dataIndex: 'is_active',
            key: 'is_active',
            width: 50,
            render: (record) => record ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CheckCircleTwoTone twoToneColor="#ccc" />
        },
        {
            title: 'Is Supperuser',
            dataIndex: 'is_superuser',
            key: 'is_superuser',
            width: 50,
            render: (record) => record ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CheckCircleTwoTone twoToneColor="#ccc" />
        },
        {
            title: 'Is Verify',
            dataIndex: 'is_verify',
            key: 'is_verify',
            width: 50,
            render: (record) => record ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CheckCircleTwoTone twoToneColor="#ccc" />
        },
        {
            title: 'Action',
            key: 'action',
            width: 120,
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => onClickView(record)}>View</a>
                    <a onClick={() => onDelete(record.id)}>Delete</a>
                </Space>
            ),
            fixed: 'right',
        }
    ]

    const onClickView = (record) => {
        setDataUser(record)
        setIsOpenViewUser(true)
    }

    const onDelete = (id) => {

    }

    return (<>
        <div className="w-full overflow-auto">
            <Table
                rowKey={'id'}
                columns={columns}
                dataSource={data}
                scroll={{ x: 'max-content' }}
            />
            <ModalViewUser 
                isModalOpen={isOpenViewUser}
                setIsModalOpen={setIsOpenViewUser}
            />
        </div>
    </>)
}

export default TableUsers