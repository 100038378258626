const Input = ({ id, name, type, required = false, value, setValue, ...props }) => {
    return (
        <input
            {...props}
            id={id}
            name={name}
            type={type}
            required={required}
            autoComplete={id}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="block w-full rounded-md border-0 px-2 py-1.5 outline-none text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
        />
    )
}

export default Input