import { useDispatch, useSelector } from "react-redux"
import AuthCMS from "../../Layouts/AuthCMS"
import Feeds from "./Components/Feeds"
import { Statistic } from "./Components/Statistic"
import Table from "./Components/Table"
import { getDashboardRequest, getDashboardState } from "../../Stores/DashboardSlice"
import { useEffect } from "react"
import TableActivity from "./Components/Table"
import { getLangModule } from "../../Stores/AuthSlice"

const Dashboard = () => {
    const dashboardData = useSelector(getDashboardState)
    const langData = useSelector(getLangModule)
    const { timeline, statistic, activity } = dashboardData
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDashboardRequest())
    }, [dispatch])


    return <AuthCMS tabActive="/dashboard">
        <div>
            <Statistic statistic={statistic} langData={langData}/>
            <div className="flex justify-center gap-8 pb-8 flex-wrap md:flex-nowrap">
                <Feeds timeline={timeline} langData={langData}/>
                <TableActivity
                    dataSource={activity}
                />
            </div>
        </div>
    </AuthCMS>
}

export default Dashboard