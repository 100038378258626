import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDashboardFetch } from "./DashboardAPI";
import { toast } from "react-toastify";

const initialState = {
    activity: [],
    timeline: [],
    statistic: {
        total_ip: 0,
        total_user: 0,
        trust: "100%",
        support_time: "24/7"
    }
}

export const getDashboardRequest = createAsyncThunk(
    'dashboard/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getDashboardFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getDashboardRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { activity, total_ip, total_user, trust, support_time, timeline } = action.payload
                state.activity = activity
                state.statistic.total_ip = total_ip
                state.statistic.total_user = total_user
                state.statistic.trust = trust
                state.statistic.support_time = support_time
                state.timeline = timeline
            })
    }
})

export const getDashboardState = (state) => state.dashboard

export default DashboardSlice.reducer