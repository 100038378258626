import Header from "./AuthCMS/Header"
import Sidebar from "./AuthCMS/Sidebar"

const AuthCMS = ({ tabActive, children }) => {
    return (<>
        <div className="flex flex-row flex-grow min-h-screen bg-gray-100 text-gray-800">
            <Sidebar tabActive={tabActive} />
            <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
                <Header />
                <div className="main-content flex flex-col flex-grow p-4 w-full">
                    <div
                        className="flex flex-col flex-grow bg-white rounded mt-4"
                    >
                        {children}
                    </div>
                </div>
                <footer className="footer px-4 py-6">
                    <div className="footer-content">
                        <p className="text-sm text-gray-600 text-center">© Proxy Center 2024. All rights reserved.</p>
                    </div>
                </footer>
            </main>
        </div>
    </>)
}

export default AuthCMS