import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getPaymentsFetch } from "./PaymentAPI";

const initialState = {
    payments: [],
    state: 'idle'
}

export const getPaymentsRequest = createAsyncThunk(
    'payments/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await getPaymentsFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const PaymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentsRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPaymentsRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { data } = action.payload
                state.payments = data
            })
    }
})

export const getPaymentsState = (state) => state.payments.payments

export default PaymentSlice.reducer