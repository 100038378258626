import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { topupsFetch } from "./TopupAPI";
import { toast } from "react-toastify";

const initialState = {
    status: 'idle',
    transaction_content: null,
    qr_code: null,
}

export const onTopupsRequest = createAsyncThunk(
    'topups/get',
    async (_, thunkAPI) => {
        const token = thunkAPI.getState().auth.accessToken;
        const response = await topupsFetch(token);
        if (response.status === 200) {
            return response.data;
        } else {
            console.log(response)
            const msg = Array.isArray(response.response.data.detail) ? response.response.data.detail[0].msg : response.response.data.detail.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }
    }
)

export const TopupSlice = createSlice({
    name: 'topup',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(onTopupsRequest.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(onTopupsRequest.fulfilled, (state, action) => {
                state.status = 'idle';
                const { status, transaction_content, qr_code } = action.payload
                if(state.transaction_content && (state.transaction_content !== transaction_content)) {
                    toast.success("Topup Success!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
                state.transaction_content = transaction_content
                state.qr_code = qr_code
                if(status) {
                    toast.success("Topup Success!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            })
    }
})

export const getTopupState = (state) => state.topup

export default TopupSlice.reducer