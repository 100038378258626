import { Input, Modal, Space } from "antd"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { createTokenRequest, getTokenListRequest } from "../../../../Stores/TokenSlice"

const ModalCreateToken = ({
    isOpen,
    setIsOpen
}) => {
    const [name, setName] = useState("")
    const [description, setDiscription] = useState("")
    const dispatch = useDispatch();

    const onOkEvent = () => {
        const dataNeedle = {
            name,
            description
        }
        dispatch(createTokenRequest(dataNeedle)).then((response) => {
            dispatch(getTokenListRequest())
        });
        setName("")
        setDiscription("")
        setIsOpen(false)
    }

    return (<>
        <Modal
            title="Tạo mới token"
            onCancel={() => setIsOpen(false)}
            onOk={onOkEvent}
            open={isOpen}
        >
            <Space direction="vertical" style={{display: "flex"}} className="pt-3">
                <Input placeholder="Name" onChange={(e) => setName(e.target.value)} value={name}></Input>
                <Input.TextArea placeholder="Description" rows={3} onChange={(e) => setDiscription(e.target.value)} value={description}/>
            </Space>
        </Modal>
    </>)
}

export default ModalCreateToken